import { certificationTransformer, subCategoryTransformer, subCategoryTransformerForDenim } from 'src/utils/generic';
import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  FETCH_QUERY_MATERIAL_REQUEST,
  FETCH_QUERY_MATERIAL_SUCCESS,
  FETCH_QUERY_MATERIAL_FAILURE,
  CUSTOMER_DATA_CLEAR
} from '../constants';

const initialState = {
  customerDataRequest: false,
  customerData: {},
  customerDataStatus: false
};

interface stateType {
  customerDataRequest: boolean;
  customerData: Object;
  customerDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const customerDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_CUSTOMER_REQUEST:
    return {
      ...state,
      customerDataRequest: true,
      customerData: {},
      customerDataStatus: false
    };

  case FETCH_CUSTOMER_SUCCESS:
    return {
      ...state,
      customerDataRequest: false,
      customerData: action.payload.data,
      customerDataStatus: true
    };
  case FETCH_CUSTOMER_FAILURE:
    return {
      ...state,
      customerDataRequest: false,
      customerData: {},
      customerDataStatus: false
    };
  case CUSTOMER_DATA_CLEAR:
    return {
      ...state,
      customerDataRequest: true,
      customerData: {},
      customerDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};


const initialStateMaterial = {
  queryMaterialDataRequest: false,
  queryMaterialData: {},
  queryMaterialDataStatus: false,
  isFetchError : false
};

interface stateTypeMaterial {
  queryMaterialDataRequest: boolean;
  queryMaterialData: Object;
  queryMaterialDataStatus: boolean;
  isFetchError: boolean
}

interface actionTypeMaterial {
  type: string;
  payload: {
    data: Object | any;
  };
}

export const queryMaterialDataReducer = (state = initialStateMaterial, action: actionTypeMaterial): stateTypeMaterial => {
  switch (action.type) {
  case FETCH_QUERY_MATERIAL_REQUEST:
    return {
      ...state,
      queryMaterialDataRequest: true,
      queryMaterialData: {},
      queryMaterialDataStatus: false,
    };

  case FETCH_QUERY_MATERIAL_SUCCESS:
    return {
      ...state,
      queryMaterialDataRequest: false,
      queryMaterialData: {...action.payload.data,
        certificationObject: certificationTransformer(action.payload.data.certification),
        fabricSubCategory: subCategoryTransformer(action.payload.data.fabricSubCategory),
        denim: subCategoryTransformerForDenim(action.payload.data.denim)
      },
      queryMaterialDataStatus: true
    };
  case FETCH_QUERY_MATERIAL_FAILURE:
    return {
      ...state,
      queryMaterialDataRequest: false,
      queryMaterialData: {},
      queryMaterialDataStatus: false,
      isFetchError : true
    };
  default:
    return {
      ...state
    };
  }
};
